//
// Stimulus pour le tracking
//

import { Application } from '@hotwired/stimulus';

import { controllerDefinitions } from './controllers/index';
import { isTrackingEnabled } from './controllers/tracking-opt-out';

window.Stimulus = Application.start();
window.Stimulus.load(controllerDefinitions);

window.isTrackingEnabled = isTrackingEnabled;
