import { Controller } from '@hotwired/stimulus';

const STORAGE_KEY = 'doNotTrack';

/**
 * Checks whether the user's do-not-track setting is enabled.
 * @returns {Boolean} `true` if the user should not be tracked.
 */
function hasDoNotTrack() {
  return (
    navigator.doNotTrack === '1' ||
    navigator.doNotTrack === 'yes' ||
    navigator.msDoNotTrack === '1' ||
    window.doNotTrack === '1'
  );
}

/**
 * Retrieves whether the user's navigation can be tracked.
 */
export function isTrackingEnabled() {
  const value = localStorage.getItem(STORAGE_KEY);
  return value === null ? !hasDoNotTrack() : value !== 'true';
}

/**
 * Set whether the user's navigation can be tracked.
 */
function setTracking(enabled) {
  localStorage.setItem(STORAGE_KEY, enabled === false ? 'true' : 'false');
}

export default class extends Controller {
  static targets = ['input'];

  static values = {
    optOut: {
      type: Boolean,
      default: !isTrackingEnabled(),
    },
  };

  // Callbacks

  inputTargetConnected(element) {
    // eslint-disable-next-line no-param-reassign
    element.checked = this.optOutValue;
    element.addEventListener('change', () => {
      this.optOutValue = element.checked;
    });
  }

  optOutValueChanged(value, previousValue) {
    if (previousValue !== undefined && value !== previousValue) {
      setTracking(value !== true);
    }
  }
}